import { createTheme } from '@mui/material';
import { Drawer } from '@mui/material';
import styled from '@emotion/styled';

const theme = createTheme({
  palette: {
    mode: 'light',
    background: {
      default: '#F8F8FA',
    },
    primary: {
      main: '#004c4c',
    },
    secondary: {
      main: '#006666',
    },
    highlight: {
      main: '#003333'
    },
    lightGreen: {
      main: '#00b2b2'
    },
    purple: {
      main: '#9D4BD2'
    },
    gray: {
      main: '#6C7373'
    },
    darkerLightGreen: {
      main: '#006666'
    },
    text: {
      primary: '#666666',
    },
    success: {
      main: '#00C875',
      contrastText: '#fff',
    },
    info: {
      main: '#61ACE6',
      contrastText: '#fff',
    },
    warning: {
      main: '#FDAB3D',
      contrastText: '#fff',
    },
    error: {
      main: '#E2445C',
      contrastText: '#fff',
    }
  },

  typography: {
    fontDisplay: 'block'
  },
  drawer: {
    width: 250
  },
  components: {
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {color:'#FF0000'},
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: '#fff',
          '&.Mui-disabled': {
            backgroundColor: '#e4e4e4',
          },
        },
      },
    },
  },
}); 
export default theme;

//DRAWER MENU STYLE
export const CustomDrawer = styled(Drawer)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    backgroundColor: '#353A40', ///theme.palette.primary.main,
    color: '#fff',
    boxSizing: 'border-box',
    width: theme.drawer.width,
    overflow: 'overlay',
    fontSize: 18,
    paddingBottom: '5rem',
    '& .Mui-selected, .Mui-selected:hover': {
      backgroundColor: '#9D4BD2', //theme.palette.secondary.main,
      position: 'relative',
    },
    '& .has-children': {
      backgroundColor: '#515858', //theme.palette.highlight.main,
      // color: theme.palette.secondary.main,
      // '& .MuiListItemIcon-root': {
      //   color: theme.palette.secondary.main
      // }
    },
    '& .has-children, .top-level': {
      '& .MuiTypography-root': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontSize: 15.5
      }
    }
  },
  '& .MuiDrawer-paper:hover': {
    overflowY: 'overlay'
  },
  '& .MuiListItemButton-root': {
    color: '#f8f8fa',
    paddingLeft: 24,
    paddingRight: 24
  },
  '& .MuiListItemIcon-root': {
    color: '#f8f8fa',
    minWidth: 0,
    marginRight: 16
  },
  '& .MuiSvgIcon-root': {
    fontSize: 20,
  },
  '& .MuiListItemText-inset': {
    paddingLeft: 37
  }
}));