import { ThemeProvider } from '@emotion/react';
import LoginForm from 'components/LoginForm';
import theme from 'theme';
import './index.css';

function Login(){
  return(
    <ThemeProvider theme={theme}>
      <div id='login-page'>
        <div id='login-form-container'>
          <LoginForm/>
        </div>
      </div>
    </ThemeProvider>
  )
} export default Login;