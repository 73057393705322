import { useState, memo } from 'react';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText, Collapse } from '@mui/material';
import { NavLink, useLocation } from 'react-router-dom';

function MenuItem({ title, route, icon, onClick, hidden, children }){
  const [ open, setOpen ] = useState(true);
  const location = useLocation();

  const handleClick = (onClick, route) => {
    if(!route) setOpen(!open);
    if(onClick) onClick();
  };

  return(
    <>
    {!hidden &&
      <ListItem dense disablePadding>
        <NavLink to={route ?? location.pathname} style={{ width: '100%' }}>
          {({ isActive }) => (
           <ListItemButton
              selected={isActive && (route !== undefined)}
              className={children ? 'has-children' : 'top-level'}
              onClick={() => handleClick(onClick, route)}
            >
              <ListItemIcon>
                {icon}
              </ListItemIcon>
              <ListItemText primary={title} />
              {children && <>{open ? <ExpandLess /> : <ExpandMore />}</>}
            </ListItemButton>
          )}
        </NavLink>
      </ListItem>
      }
      {children &&
        <Collapse in={open} timeout='auto' unmountOnExit>
          <List dense disablePadding>
            {children.map((child, index) => !child.hidden &&
              <NavLink to={child.route} key={index}>
                {({ isActive }) => (
                  <ListItemButton selected={isActive}
                    id={child.route.substring(1)}
                    onClick={() => handleClick(child.onClick, child.route)}
                  >
                    <ListItemText inset primary={child.title} />
                  </ListItemButton>
                )}
              </NavLink>
            )}
          </List>
        </Collapse>
      }
    </>
  )
} export default memo(MenuItem, () => true);