import { Snackbar, Alert } from '@mui/material';

function Message(props){

  const handleClose = (_, reason) => {
    if(reason === 'clickaway') return;
    props.setOptions(state => ({...state, open: false}));
  };

  return(
    <Snackbar
      open={props.options.open}
      onClose={handleClose}
      autoHideDuration={2000}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
    >
      <Alert
        severity={props.options.severity}
        onClose={handleClose}
        variant='filled'
      >
        {props.options.message}
      </Alert>
    </Snackbar>
  )
} export default Message;