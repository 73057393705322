import ApiWrapper from './ApiWrapper';
import { getUserSession } from 'utils/userUtils';

class OrganizerAPI extends ApiWrapper{
  constructor() {
    super(process.env.REACT_APP_API_URL);
  }

  getAll(query?: any, orderBy?: any, filters?: any){
    return this.fetch({
      endpoint: 'OrganizerTasksList',
      method: 'POST',
      body: { orderBy, ...filters },
      query
    });
  }

  create(data: any){
    return this.fetch({
      endpoint: `OrganizerTasks`,
      method: 'POST',
      body: { ...data, GUID_ESTAB: getUserSession()?.estab }
    });
  }

  update(data: any, GUID: any){
    return this.fetch({
      endpoint: `OrganizerTasks/${GUID}`,
      method: 'PUT',
      body: data
    });
  }

  delete(GUID: any){
    return this.fetch({
      endpoint: `OrganizerTasks/${GUID}`,
      method: 'DELETE'
    });
  }

  getTaskDetails(GUID: uuidv4){
    return this.fetch({
      endpoint: 'DetailOrgTasksList',
      method: 'POST',
      body: {
        condicaoWhere: {
          GUID_ORG_TAREFAS: GUID
        }
      }
    });
  }

  addTaskDetails(GUID: uuidv4, DESCRICAO_CONFIRMACAO: string){
    return this.fetch({
      endpoint: 'DetailOrgTasks',
      method: 'POST',
      body: { 
        GUID_ORG_TAREFAS: GUID,
        DESCRICAO_CONFIRMACAO
      }
    });
  }

};

const organizerAPI = new OrganizerAPI();
export default organizerAPI;