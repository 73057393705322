import { useState } from 'react';
import { useLocation, useNavigate, Navigate } from 'react-router-dom';
import { Grid, Backdrop, CircularProgress, ThemeProvider } from '@mui/material';
import { useForm } from 'react-hook-form';
import { isEmailValid } from 'utils/validationUtils';
import { isUserLoggedIn } from 'utils/userUtils';
import { resetPassword } from './controller';
import theme from 'theme';
import Typography from 'components/Typography';
import Form from 'components/Form';
import FormItem from 'components/FormItem';
import TextField from 'components/TextField';
import Button from 'components/Button';
import logo from 'assets/images/logo.png';
import logoPraxio from 'assets/images/logo_praxio.png';

function ResetPassword(){
  const location = useLocation();
  const navigate = useNavigate();

  const [ loading, setLoading ] = useState(false);
  const [ successMessage, setSuccessMessage ] = useState();
  const [ errorMessage, setErrorMessage ] = useState();
  const from = location.state?.from?.pathname || '/';
  const state = location.state?.from?.state || [];

  const form = useForm();
  const { handleSubmit, control } = form;

  const handleResetPassword = (values) => {
    setLoading(true);

    resetPassword(values)
      .then(resp => {
        setSuccessMessage(resp.data);
        setLoading(false);
      })
      .catch(error => {
        setErrorMessage(error.message); 
        setLoading(false);
      })
  };

  return(
    isUserLoggedIn()
    ?
      <Navigate to={from} state={{ from: location, state: state ?? [] }} replace/>
    :
      <ThemeProvider theme={theme}>

        <Backdrop
          open={loading}
          sx={{ zIndex: 99 }}
        >
          <CircularProgress
            sx={{ color: theme.palette.primary.main }}
            variant='indeterminate'
            size={40}
            thickness={4}
          />
        </Backdrop>

        <div id='login-page'>
          <div id='login-form-container'>

            <img src={logo} alt='Logo' />
            {/* <Typography variant='h4' component='h2'>Siga Emissor</Typography> */}

            <Form id='login-form' onSubmit={handleSubmit(handleResetPassword)}>
              {!successMessage
                ?
                  <>
                    <Grid item container spacing={2}>
                      <FormItem label='Email' name='EMAIL' control={control}
                        rules={{ required: true, validate: (EMAIL) => isEmailValid(EMAIL) || 'Insira um email válido' }}
                      >
                        <TextField/>
                      </FormItem>
                    </Grid>

                    <Button fullWidth variant='contained' type='submit'>Trocar Senha</Button>
                    <Typography sx={{ color: 'red' }}>{errorMessage}</Typography>
                  </>
                :
                  <>
                    <Typography>{successMessage}</Typography>
                    <Button
                      fullWidth
                      variant='outlined'
                      type='submit'
                      onClick={() => navigate('/login')}
                    >
                      Voltar ao login
                    </Button>
                  </>
              }
            </Form>
            <img src={logoPraxio} alt='Logo Praxio' />
          </div>
        </div>
        
      </ThemeProvider>
  );
};

export default ResetPassword;