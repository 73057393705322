import { Modal, Paper, Grid, Typography, Button, DialogActions } from '@mui/material';
import { getParseRecurrence } from './controller';
import { useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import TextField from 'components/TextField';
import Form from 'components/Form';
import FormItem from 'components/FormItem';
import organizerAPI from 'apis/OrganizerAPI';
import { PriorityLabel } from '.';

interface TaskModalProps {
  open: boolean,
  onClose: () => void,
  selectedTask: any,
  onTaskFinish: (GUID: string) => void
}

function TaskModal(props: TaskModalProps) {

  const form = useForm();
  const { handleSubmit, control } = form;

  if (props.selectedTask.RECORRENCIA_TAREFA > 0) {
    let TIME = props.selectedTask.HORARIO.match(/.{1,2}/g);
    props.selectedTask.DATA_INICIO = new Date(props.selectedTask.DATA_INICIO).setHours(TIME[0], TIME[1], 0);
  };

  const DATA_INICIO = dayjs(props.selectedTask.DATA_INICIO).format('DD/MM/YYYY - HH:mm');
  const DATA_FIM = dayjs(props.selectedTask.DATA_FIM).format('DD/MM/YYYY - HH:mm');

  const finishTask = (values: any) => {
    const GUID = props.selectedTask.GUID;
    const TASK_DATA = {
      ...props.selectedTask,
      ...values,
      DATA_CONCLUIDA: new Date().toISOString()
    }

    organizerAPI.update(TASK_DATA, GUID).then(() => {
      organizerAPI.addTaskDetails(GUID, values.DESCRICAO_CONFIRMACAO).then(() => {
        props.onTaskFinish(GUID);
      });
    });
  };

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '90%',
    outline: 'none',
    width: 800,
    p: 2,
  };

  return (
    <Modal
      open={props.open}
      onClose={props.onClose}
    >
      <Paper sx={modalStyle}>
        <Form onSubmit={handleSubmit(finishTask)} sx={{ my: 0 }}>
          <Grid container rowSpacing={5} alignItems='center'>
            <Grid item xs={9}>
              <Typography variant='h6' component='h2'>{props.selectedTask.TITULO}</Typography>
              <Grid container spacing={3}>
                <Grid item>
                  <Typography> Inícia em: <span style={{ color: '#009999' }}>{DATA_INICIO}</span> </Typography>
                </Grid>
                <Grid item>
                  {props.selectedTask.DATA_FIM &&
                    <Typography> Termina em: <span style={{ color: '#009999' }}>{DATA_FIM}</span> </Typography>
                  }
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <Grid container justifyContent='end' rowSpacing={0.25} spacing={2} sx={{ alignItems: 'center' }}>
                <Grid item>
                  <Typography>Prioridade</Typography>
                </Grid>
                <Grid item>
                  <PriorityLabel width={130} status={props.selectedTask.PRIORIDADE} />
                </Grid>
                <Grid item>
                  <Typography variant='caption' > Recorrência: <span style={{ color: '#000' }}>{getParseRecurrence(props.selectedTask.RECORRENCIA_TAREFA)}</span> </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid py={3}>
            <Typography variant='body1'>
              {props.selectedTask.DESCRICAO}
            </Typography>
          </Grid>
          {/* 
          // @ts-ignore */}
          <FormItem label={'Observação'} name='DESCRICAO_CONFIRMACAO' control={control} md={2} pr={1} pb={1}>
            <TextField multiline rows={3} />
          </FormItem>
          <DialogActions>
            <Button onClick={props.onClose}>Fechar</Button>
            <Button type='submit' variant='outlined'>Concluir Tarefa</Button>
          </DialogActions>
        </Form>
      </Paper>
    </Modal>
  )
};

export default TaskModal;