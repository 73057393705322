import { cloneElement } from 'react';
import { Grid } from '@mui/material';
import { Controller } from 'react-hook-form';

function FormItem({ children, name, label, control, rules, id, error, ...props }){

  rules = {
    ...rules,
    required: rules?.required === true ? `${label} é obrigatório.` : rules?.required,
    minLength: typeof rules?.minLength === 'number' ?
      { value: rules.minLength, message: `${label} precisa ter mais de ${rules.minLength} caracteres.`} :
      { ...rules?.minLength },
    maxLength: typeof rules?.maxLength === 'number' ?
      { value: rules.maxLength, message: `${label} não pode ser maior que ${rules.maxLength} caracteres.`} :
      { ...rules?.maxLength },
    min: typeof rules?.min === 'number' ?
      { value: rules.min, message: `${label} precisa ser maior que ${rules.min}.`} :
      { ...rules?.min },
    max: typeof rules?.max === 'number' ?
      { value: rules.max, message: `${label} precisa menor que ${rules.max}.`} :
      { ...rules?.max }
  };

  return(
    <Grid item xs={12} {...props}>
      <Controller
        name={name}
        control={control}
        rules={rules}
        defaultValue={props.defaultValue ?? ''}
        render={({ field: { ref, ...field }, fieldState }) => (
          cloneElement(children, {
            inputRef: ref,
            label,
            ...field,
            id: id ?? name,
            value: children.props.mask ? String(field.value) : field.value,
            required: !!rules?.required,
            error: error ?? !!fieldState.error,
            helperText: children.props.helperText ?? fieldState.error?.message,
            onChange: (e) => {
              field.onChange(e)
              if(typeof props.onChange !== 'undefined')
                props.onChange(e)
            }
          })
        )}
      />
    </Grid>
  )
} export default FormItem;