import { Backdrop, Box, CircularProgress, Typography } from '@mui/material';
import theme from 'theme';

function Loading(props){
  return(
    <Backdrop
      open={props.open ?? true}
      sx={{
        zIndex: 99,
        backgroundColor: `${theme.palette.background.default}bb`,
        marginLeft: { sm: `${theme.drawer.width}px` }
      }}
    >
      <Box sx={{ textAlign: 'center', display: 'block' }}>
        <CircularProgress
          sx={{ color: theme.palette.secondary.main }}
          variant='indeterminate'
          size={40}
          thickness={4}
        />
        <Typography
          variant='h6'
          color={theme.palette.secondary.main}
          display={'block'}
        >
          Siga Emissor
        </Typography>
        <Typography display={'block'}>
          Por favor, aguarde...
        </Typography>
      </Box>
    </Backdrop>
  )
} export default Loading;