import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Button from 'components/Button';

function Confirmation(props){
  const { t } = useTranslation('Actions');

  const handleConfirm = (confirmation) => {
    props.setConfirmation(prevState => ({...prevState, open: false}));
    props.resolve(confirmation);
  };

  return(
    //DIALOG BODY
    <Dialog open={props.open ?? false}>
      {/* //DIALOG TITLE */}
      <DialogTitle>
        {props.title}
      </DialogTitle>
       {/* //DIALOG CONTENT */}
      <DialogContent>
        <DialogContentText>
          {props.text}
        </DialogContentText>
      </DialogContent>
       {/* //DIALOG ACTIONS */}
      <DialogActions>
        <Button id='confirmar' onClick={() => handleConfirm(true)} color='error'>{t('Confirmar')}</Button>
        <Button id='cancelar' onClick={() => handleConfirm(false)}>{t('Cancelar')}</Button>
      </DialogActions>
    </Dialog>
  )
} export default Confirmation;