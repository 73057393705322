import { Box, Typography } from '@mui/material';

function Error({ icon, title, subtitle, extra }){

  return(
    <Box sx={{ textAlign: 'center', py: 5, '& > :not(style)': { my: 0.8 } }}>
      {icon}
      <Typography variant='h5'>
        {title}
      </Typography>
      <Typography variant='subtitle1'>
        {subtitle}
      </Typography>
      {extra}
    </Box>
  );
} export default Error;