import ApiWrapper from './ApiWrapper';

class PermissionAPI extends ApiWrapper{
  constructor() {
    super(process.env.REACT_APP_API_URL);
  }

  getAll(GUID){
    return this.fetch({
      endpoint: `listPermission/${GUID}`,
      method: 'GET'
    });
  }

  create(data){
    return this.fetch({
      endpoint: 'permission',
      method: 'POST',
      body: { rows: data }
    });
  }

  update(data){
    return this.fetch({
      endpoint: 'permission',
      method: 'PUT',
      body: { rows: data }
    });
  }
}
const permissionAPI = new PermissionAPI();
export default permissionAPI;