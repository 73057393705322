import { isUserLoggedIn, getSessionToken } from 'utils/userUtils';

class ApiWrapper{
  constructor(base_url) {
    this.BASE_URL = base_url;
  }

  async http(request){
    const response = await fetch(request);

    if(!response.ok) {
      const responseBody = await response.json();
      throw new Error(
        typeof responseBody.data === 'string'
          ? responseBody.data
          : JSON.stringify(responseBody.data)
      );
    }
    
    // may error if there is no body, return the response itself
    // and so the caller decides what to do with it
    return response.clone().json().catch(() => response);
  };

  async fetch(options){
    if(!isUserLoggedIn){
      const locationState = window.history?.state?.usr ?? [];

      window.history.pushState({ from: window.location.pathname, state: locationState }, 'Login', '/login');
      window.location.reload();
    }

    const urlEncoded = options?.query ? '?' + new URLSearchParams(options.query).toString() : '';
    const requestURL = `${this.BASE_URL}/${options.endpoint}${urlEncoded}`;
    const accessToken = getSessionToken();

    const request = new Request(requestURL, {
      method: options?.method ?? 'GET',
      headers: {
        ...(options?.contentType !== 'auto' && {'Content-Type': options?.contentType ?? 'application/json'}),
        ...(accessToken && {'Authorization': `Bearer ${accessToken}`})
      },
      ...((options?.method !== 'GET') && { body : options?.contentType ? options?.body : JSON.stringify(options?.body)})
    })
    return await this.http(request);
  }

} export default ApiWrapper;