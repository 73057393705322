export function registerSession(data){
  localStorage.setItem('USER_SESSION', JSON.stringify(data));
};

export function deleteSession(){
  localStorage.removeItem('USER_SESSION');
};

export function getSessionToken(){
  return JSON.parse(localStorage.getItem('USER_SESSION'))?.token ?? '';
};

export function getUserSession(){
  const sessionData = JSON.parse(localStorage.getItem('USER_SESSION'));

  if(sessionData){
    const sessionToken = sessionData.token;
    const base64Url = sessionToken.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  
    const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
  
    return JSON.parse(jsonPayload);
  }
};

export function isUserLoggedIn(){
  //GET CURRENT TIME STAMP
  const currentDate = new Date();
  const timestamp = parseInt(currentDate.getTime() / 1000);
  
  const userSession = getUserSession();

  if(userSession){
    return timestamp < userSession.exp; //CHECK IF USER TOKEN IS NOT EXPIRED
  }else
    return false;
};

export function getPermissions(route){
  if(getUserSession().Super){
    return { view: true, include: true, update: true, delete: true }
  };

  let permissions = sessionStorage.getItem('permissions');
  permissions = JSON.parse(permissions) ?? {};

  if(permissions.length)
    return permissions.find(el => el.route === route) ?? {};
  else
    return {}
};