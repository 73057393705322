import { Fragment, useState, useEffect, useLayoutEffect, useRef } from 'react';
import { Button, Chip, Menu, Typography, MenuItem, ListItemText, IconButton } from '@mui/material';
import { disableSoundAlert, getTasks } from './controller';
import { VolumeOffRounded, VolumeUpRounded } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import alarmSound from '../../assets/sounds/alarm.mp3';
import TaskModal from './TaskModal';
import dayjs from 'dayjs';
import './style.css';

const TaskBar: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const alarm:any = useRef(new Audio(alarmSound));
  const [ activeAlarm, setActiveAlarm ] = useState<any>([]);
  const [ taskModal, setTaskModal ] = useState({ open: false, selectedTask: {} });
  const [ anchorEl, setAnchorEl ] = useState<null | HTMLElement>(null);
  const [ tasksList, setTasksList ] = useState<any>([]);

  const loadTasks = async () => {
    return getTasks().then((resp) => {

      resp.data.rows = resp.data.rows.filter((el: any) => {
        let weekDay = (new Date()).getDay();
        return !((el.RECORRENCIA_TAREFA === 4) && ( weekDay === 0 || weekDay === 6 ))
      });

      resp.data.rows.forEach((el: any) => {
        let DATA_INICIO = Date.parse(el.DATA_INICIO);
        let DATA_ATUAL = Date.parse(new Date().toISOString());

        if(el.RECORRENCIA_TAREFA > 0){
          let TIME = el.HORARIO.match(/.{1,2}/g);
          DATA_INICIO = new Date(DATA_INICIO).setHours(TIME[0],TIME[1],0)
        };

        setTimeout(() => {
          setActiveAlarm((currentState: any) => [...currentState, el.GUID]);
          if(el.ALERTA_SONORO){
            alarm.current.loop = true;
            alarm.current.currentTime = 0;
            alarm.current.muted = true;
            alarm.current.play();
            alarm.current.muted = false;
          }
        }, DATA_INICIO - DATA_ATUAL);
      });

      setTasksList(resp.data.rows)
    });
  };

  const handleEnableDisableSound = (ALERTA_SONORO: boolean, GUID: string) => {
    disableSoundAlert(ALERTA_SONORO, GUID).then(() => {
      alarm.current.pause();
      loadTasks();
    }).catch(error => {
      console.log(error);
    });
  };

  const handleTaskFinish = (GUID: string) => {
    loadTasks().then(() => {
      alarm.current.pause();

      setActiveAlarm((currentState: []) => {
        return currentState.filter((item) => item !== GUID);
      });
  
      setTaskModal({
        open: false,
        selectedTask: {}
      });

      window.location.reload();
    });
  };

  useEffect(() => {
    loadTasks();
  }, []);

  useLayoutEffect(() => () => {
    if (alarm.current.duration > 0 && !alarm.current.paused) {
      alarm.current.pause();
    };
  }, []);

  return(
    <Fragment>
      <Button
        onMouseOver={(event) => setAnchorEl(event.currentTarget)}
        className={activeAlarm.length > 0 ? 'blink' : ''}
        sx={{ paddingX: 3, textTransform: 'none', backgroundColor: '#353A40', ':hover': { backgroundColor: '#353A40' }, color: '#fff' }}
      >
        {t('Lista de Tarefas')}
        <Chip sx={{ marginLeft: 5 }} label={tasksList.length} color='error' size="small"/>
      </Button>
      {tasksList.length > 0 &&
        <Menu
          id='menu-appbar'
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
          keepMounted
          sx={{
            marginTop: 0.8,
            '& .MuiList-root .MuiMenuItem-root': {
              backgroundColor: '#D8E8E7'
            }
          }}
          PaperProps={{
            sx: {
              width: 450,
              paddingX: 1
            }
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          {tasksList.map((task: any, i: number) =>
            <MenuItem className={activeAlarm.includes(task.GUID) ? 'blink' : ''} onClick={() => setTaskModal({ open: true, selectedTask: task })} key={i} sx={{ borderRadius: 1.5, marginBottom: 1, color: '#607D7C' }} >
              <ListItemText sx={{ maxWidth: '210px' }} primary={task.TITULO} primaryTypographyProps={{ overflow: 'hidden', textOverflow: 'ellipsis' }}/>
              <IconButton onClick={(e) => {handleEnableDisableSound(task.ALERTA_SONORO, task.GUID); e.stopPropagation()}} size='small'>
                {task.ALERTA_SONORO
                  ? <VolumeUpRounded sx={{ color: '#006666' }} />
                  : <VolumeOffRounded sx={{ color: '#999999' }} />
                }
              </IconButton>
              <PriorityLabel width={100} status={task.PRIORIDADE}/>
              <Typography sx={{ paddingLeft: 2 }}>{dayjs(task.DATA_INICIO).format('HH:mm')}</Typography>
            </MenuItem>
          )}
          <Button size='small' onClick={() => navigate('tarefas')}>Ver todas</Button>
        </Menu>
      }
      <TaskModal
        open={taskModal.open}
        onClose={() => setTaskModal({ open: false, selectedTask: {} })}
        selectedTask={taskModal.selectedTask}
        onTaskFinish={handleTaskFinish}
      />
    </Fragment>
  )
};
export default TaskBar;

export function PriorityLabel({ status, width }: { status: number, width: number }){

  let statusLabel = {};

  switch(status){
    case 1:
      statusLabel = { label: 'Baixa', color:'success' };
      break;
    case 2:
      statusLabel = { label: 'Média', color:'warning' };
      break;
    case 3:
      statusLabel = { label: 'Urgente', color:'error' };
      break;
    default:
      statusLabel = { label: 'Nenhuma', color:'default' };
  }

  return (
    <Chip sx={{ width }} {...statusLabel} variant='filled' />
  );
};