import ApiWrapper from './ApiWrapper';
import { getUserSession } from 'utils/userUtils';

class UserAPI extends ApiWrapper{
  constructor() {
    super(process.env.REACT_APP_API_URL);
  }

  login(data){
    return this.fetch({
      endpoint: 'login',
      method: 'POST',
      body: data
    });
  }

  getAll(query, orderBy, filters){
    return this.fetch({
      endpoint: 'listUser',
      method: 'POST',
      body: { orderBy, ...filters },
      query: { GUID_ESTAB: getUserSession()?.estab, ...query }
    });
  }

  create(data){
    return this.fetch({
      endpoint: 'user',
      method: 'POST',
      body: { ...data, GUID_ESTAB: getUserSession()?.estab }
    });
  }

  update(data, GUID){
    return this.fetch({
      endpoint: `user/${GUID}`,
      method: 'PUT',
      body: { ...data, GUID_ESTAB: getUserSession()?.estab }
    });
  }

  delete(id){
    return this.fetch({
      endpoint: `user/${id}`,
      method: 'DELETE',
    });
  }

  getProfilePic(id){
    return this.fetch({
      endpoint: `photoUser/${id}`,
      method: 'GET',
    });
  }

  resetPassword(body){
    return this.fetch({
      endpoint: 'user/resetPassword',
      method: 'POST',
      body
    });
  };
}
const userAPI = new UserAPI();
export default userAPI;