import permissionAPI from 'apis/PermissionAPI';
import { getUserSession } from 'utils/userUtils';

export function getPermissions(){
  return new Promise((resolve, reject) => {
    
    if(getUserSession().Super){
      return resolve(true);
    };

    permissionAPI.getAll(getUserSession().idProfile).then(response => {
      response = response.data.rows;
      
      let permissionList = response.map(el => ({
        route: el.ROTA,
        view: !!el.VISUALIZAR,
        include: !!el.INCLUI,
        update: !!el.ALTERA,
        delete: !!el.EXCLUI,
      }));

      sessionStorage.setItem('permissions', JSON.stringify(permissionList));
      resolve(permissionList)
    }).catch(error =>
      reject(error)
    );
  });
};