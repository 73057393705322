import { Navigate, Outlet, useOutletContext, useLocation, useMatches, useNavigate } from 'react-router-dom';
import { getPermissions, isUserLoggedIn } from 'utils/userUtils';
import { LockPerson } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import Error from 'components/Error';
import Button from 'components/Button';

function ProtectedRoute(){
  const outletContext = useOutletContext();
  const location = useLocation();
  const matches = useMatches();

  let routePermission = matches
    .find((match) => Boolean(match.handle?.permission))
    ?.handle?.permission;

  return(
    !isUserLoggedIn()
      ? <Navigate to='/login' state={{ from: location, state: location?.state ?? [] }} replace/>
      : getPermissions(routePermission).view || !routePermission
        ? <Outlet context={outletContext}/>
        : <PermissionError/>
  )
} export default ProtectedRoute;

function PermissionError(){
  const { t } = useTranslation();
  const navigate = useNavigate();

  return(
    <Error
      icon={<LockPerson fontSize='large'/>}
      title={t('Você não tem permissão para acessar esta página.')}
      subtitle={t('Solicite acesso ao administrador do sistema.')}
      extra={<Button variant='outlined' onClick={() => navigate('/')}>{t('Voltar ao Início')}</Button>}
      action={() => navigate('/')}
    />
  )
};