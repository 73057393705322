import { Box } from '@mui/material';

export default function Form(props){

  const handleSubmit = (event) => {
    event.preventDefault();

    var formData = new FormData(event.target);
    let values = Object.fromEntries(formData);

    Object.keys(values).forEach((k) =>
      values[k] === '' && delete values[k]);

    props.onSubmit(values);
  }

  return(
    <Box
      component='form'
      sx={{ '& > :not(style)': { my: 1} }}
      onSubmit={handleSubmit}
      noValidate
      {...props}
    />
  )
};