import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en_US from 'locales/en-us.json';

i18n.use(initReactI18next).init({
  resources: {
    'en-US': {
      translation: en_US,
      Pages: en_US.Pages,
      Actions: en_US.Actions,
      Dashboard: en_US.Dashboard
    }
  },
  lng: localStorage.getItem('lang') ?? 'pt-BR',

  nsSeparator: false,
  keySeparator: false,
  fallbackLng: false
});

export default i18n;