import { memo } from 'react';
import { Box } from '@mui/material';
import { CustomDrawer } from 'theme';
import SideMenu from 'components/SideMenu';
import DrawerTop from './DrawerTop';
import DrawerBottom from './DrawerBottom';

function DrawerMenu(props){

  const { window } = props;
  const container = window !== undefined ? () => window().document.body : undefined;

  const style = {
    drawerBox: {
      width: {
        sm: props.drawerWidth
      },
      flexShrink: {
        sm: 0
      }
    },
    drawerMobile: {
      display: {
        xs: 'block',
        sm: 'none'
      }
    },
    drawer: {
      display: {
        xs: 'none',
        sm: 'block'
      }
    }
  };

  return(
    <Box
      component='nav'
      sx={style.drawerBox}
    >
      <CustomDrawer
        container={container}
        variant='temporary'
        open={props.mobileOpen}
        onClose={props.handleDrawerToggle}
        ModalProps={{ keepMounted: true }} //Better open performance on mobile.
        sx={style.drawerMobile}
      >
        <DrawerTop/>
        <SideMenu/>
        <DrawerBottom/>
      </CustomDrawer>
      <CustomDrawer
        variant='permanent'
        sx={style.drawer}
      >
        <DrawerTop/>
        <SideMenu/>
        <DrawerBottom/>
      </CustomDrawer>
    </Box>
  )
} export default memo(DrawerMenu);