export function isEmailValid(email){
  if(email === '') return true;

  var reg= /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/gi;
  return reg.test(email);
};

export function isCpfValid(CPF) {
  if(CPF === '') return true;

  CPF = CPF.replace(/[^\d]+/g,'');
  var Resto, Soma = 0;

  for (let i=1; i<=9; i++) Soma = Soma + parseInt(CPF.substring(i-1, i)) * (11 - i);
  Resto = (Soma * 10) % 11;

    if ((Resto === 10) || (Resto === 11))  Resto = 0;
    if (Resto !== parseInt(CPF.substring(9, 10)) ) return false;

  Soma = 0;
    for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(CPF.substring(i-1, i)) * (12 - i);
    Resto = (Soma * 10) % 11;

    if ((Resto === 10) || (Resto === 11))  Resto = 0;
    if (Resto !== parseInt(CPF.substring(10, 11) ) ) return false;
    return true;
};

export function isCnhValid(CNH) {
  var char1 = CNH.charAt(0);

  if (CNH.replace(/[^\d]/g, '').length !== 11 || char1.repeat(11) === CNH) {
    return false;
  }
 
  for (var i = 0, j = 9, v = 0; i < 9; ++i, --j) {
    v += +(CNH.charAt(i) * j);
  }

  var dsc = 0,
      vl1 = v % 11;

  if (vl1 >= 10) {
    vl1 = 0;
    dsc = 2;
  }

  for (i = 0, j = 1, v = 0; i < 9; ++i, ++j) {
    v += +(CNH.charAt(i) * j);
  }

  var x = v % 11;
  var vl2 = (x >= 10) ? 0 : x - dsc;

  return ('' + vl1 + vl2) === CNH.substr(-2);
};

export function isCnpjValid (CNPJ) {
  if(!CNPJ) return false;

  var b = [ 6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2 ]
  var c = String(CNPJ).replace(/[^\d]/g, '')
  
  if(c.length !== 14)
      return false

  if(/0{14}/.test(c))
      return false

  for (var i = 0, n = 0; i < 12; n += c[i] * b[++i]);
  if(c[12] != (((n %= 11) < 2) ? 0 : 11 - n))
      return false

  for (i = 0, n = 0; i <= 12; n += c[i] * b[i++]);
  if(c[13] != (((n %= 11) < 2) ? 0 : 11 - n))
      return false

  return true
};

export function isPhoneValid(PHONE){
  if(!PHONE) return true;

  PHONE = PHONE.replace(/[^\d]+/g,'');
  return (PHONE.length === 10) || (PHONE.length === 11);
};

export function isCepValid(CEP){
  if(CEP === '') return true;

  CEP = CEP.replace(/[^\d]+/g,'');
  return /^[0-9]{8}$/.test(CEP);
};

export function isDatePast(DATE) {
  const currentDate = new Date();
  const dateSelectedObject = new Date(DATE);

  return dateSelectedObject > currentDate || dateSelectedObject.toDateString() === currentDate.toDateString();
}

export function isDateUnderAge(DATE) {
  const today = new Date();
  const selectedDate = new Date(DATE);
  const minDate = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate());
  return selectedDate < minDate;
}

export function isOnlyNumbers(string){
  return /^\d+$/.test(string);
};

export function isCityCodeValid(ID){
  const ID_CIDADE = ID.toString().replace(/\D/g, '');
  return ID_CIDADE.length === 7;
};