import { Box, LinearProgress } from '@mui/material';

const PageLoading: React.FC = () => {
  return(
    <Box sx={{ width: '100%' }}>
      <LinearProgress/>
    </Box>
  )
};

export default PageLoading;