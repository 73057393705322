import theme from 'theme';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from 'components/Toolbar';
import DrawerMenu from 'components/DrawerMenu';
import Loading from 'components/Loaging';
import Message from 'components/Message';
import Confirmation from 'components/Confirmation';
import { Suspense, useState, memo } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { Await, Outlet } from 'react-router-dom';
import { getPermissions } from './controller';
import PageLoading from 'components/PageLoading';

function PageLayout() {

  const [permissions] = useState(getPermissions());
  const [confirmation, setConfirmation] = useState({});
  const [mobileOpen, setMobileOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({
    open: false, message: '', severity: 'success'
  });

  const showMessage = (severity, message) => {
    setMessage({
      open: true,
      severity: severity,
      message: message
    });
  };

  const showConfirmation = (props) => {
    const confirmationPromise = new Promise((resolve) => {
      setConfirmation({
        ...props, open: true,
        setConfirmation: setConfirmation,
        resolve: resolve
      });
    });

    return confirmationPromise;
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return(
    <ThemeProvider theme={theme}>
      <CssBaseline/>
      <Box sx={{ display: { xs: 'block', sm: 'flex' } }}>
        <Suspense fallback={<PageLoading/>}>
          <Await resolve={permissions}>
            <DrawerMenu
              drawerWidth={theme.drawer.width}
              mobileOpen={mobileOpen}
              handleDrawerToggle={handleDrawerToggle}
            />
            <Box
              component='main'
              sx={{ mt: 5, flexGrow: 1, p: 3, width: { sm: `calc(100% - ${theme.drawer.width}px)` } }}
            >
              <Toolbar handleDrawerToggle={handleDrawerToggle} drawerWidth={theme.drawer.width} />
              <Loading open={loading}/>
              <Message options={message} setOptions={setMessage}/>
              <Confirmation {...confirmation}/>
              <Suspense fallback={<Loading/>}>
                <Outlet context={[setLoading, showMessage, showConfirmation]}/>
              </Suspense>
            </Box>
          </Await>
        </Suspense>
      </Box>
    </ThemeProvider>
  )
} export default memo(PageLayout);