import { Breadcrumbs as Muibreadcrumbs, Link } from '@mui/material';
import { HomeRounded, NavigateNextRounded } from '@mui/icons-material';
import { useMatches, NavLink } from 'react-router-dom';
import { cloneElement } from 'react';

function Breadcrumbs(){
  let matches = useMatches();

  let crumbs = matches
    .filter((match) => Boolean(match.handle?.crumb))
    .map((match) => match.handle.crumb);

  return(
    <Muibreadcrumbs separator={<NavigateNextRounded/>}>
      <Link underline='none' component={NavLink} to='/'><HomeRounded sx={{ mb: -0.6 }} fontSize='small'/></Link>
      {!crumbs.length && <Link>Dashboard</Link>}
      {crumbs.map((crumb, index) => ( 
        cloneElement(crumb, {
          key: index,
          underline: crumbs.length !== (index + 1) ? 'none' : 'always',
          ...(crumbs.length === (index + 1) && {style: { cursor: 'unset' }})
        })
      ))}
    </Muibreadcrumbs>
  )
} export default Breadcrumbs;