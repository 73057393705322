import { useState } from 'react';
import { deleteSession, getUserSession } from 'utils/userUtils';
import { Box, Menu, MenuItem, Typography, IconButton, ListItemIcon, ListItemText } from '@mui/material';
import { LogoutRounded, Person2Rounded } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LanguageMenu from 'components/LanguageMenu';

function UserMenu(){
  const navigate = useNavigate();
  const [ anchorElUser, setAnchorElUser ] = useState(null);
  const { t } = useTranslation('Actions');

  function loggout(){
    deleteSession();
    navigate('login');
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return(
    <Box>
      <IconButton
        sx={{
          borderRadius: 0,
          minHeight: '48px',
          height: '100%',
          marginRight: '-24px',
          backgroundColor: '#009A7D',
          paddingX: 2,
          color: '#fff'
        }}
        onClick={handleOpenUserMenu}
        disableRipple
      >
        <Person2Rounded/>
      </IconButton>
      <Menu
        id='menu-appbar'
        anchorEl={anchorElUser}
        open={Boolean(anchorElUser)}
        sx={{
          left: '24px'
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={handleCloseUserMenu}
        keepMounted
      >

        {/* USE NAME */}
        <MenuItem disableRipple>
          <Typography>{getUserSession()?.nome ?? ''}</Typography>
        </MenuItem>

        {/* USER EMAIL */}
        <MenuItem disableRipple>
          <Typography>{getUserSession()?.email ?? ''}</Typography>
        </MenuItem>

        {/* LANGUAGE MENU */}
        {/* <LanguageMenu/> */}

        {/* LOGGOUT BUTTON */}
        <MenuItem onClick={loggout}>
          <ListItemIcon>
            <LogoutRounded/>
          </ListItemIcon>
          <ListItemText>
            {t('Sair')}
          </ListItemText>
        </MenuItem>

      </Menu>
    </Box>
  )
} export default UserMenu;