import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import squareLogo from 'assets/images/square_logo.png';

function DrawerTop(){
  const navigate = useNavigate();

  const style = {
    textAlign: 'center',
    margin: '1rem 0',
    cursor: 'pointer'
  };

  return(
    <Box
      onClick={() => navigate('/')}
      sx={style}
    >
      <img src={squareLogo}/>
    </Box>
  )
} export default DrawerTop;