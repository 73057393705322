import { getUserSession } from 'utils/userUtils';
import ApiWrapper from './ApiWrapper';

class CompanyAPI extends ApiWrapper{
  constructor() {
    super(process.env.REACT_APP_API_URL);
  }
  
  getAll(query, orderBy, filters){
    return this.fetch({
      endpoint: 'companyList',
      method: 'POST',
      body: { orderBy, ...filters },
      query
    });
  }

  get(GUID){
    return this.fetch({
      endpoint: `company/${GUID}`,
      method: 'GET'
    });
  }

  create(data){
    return this.fetch({
      endpoint: 'company',
      method: 'POST',
      body: data
    });
  }

  update(data, GUID){
    return this.fetch({
      endpoint: `company/${GUID}`,
      method: 'PUT',
      body: data
    });
  }

  delete(GUID){
    return this.fetch({
      endpoint: `company/${GUID}`,
      method: 'DELETE',
    });
  }

  updatePrivacyPolice(data){
    const formData = new FormData();
    formData.append('privacyPolicy', data.privacyPolicy);

    return this.fetch({
      endpoint: `company/setPrivacyPolicy/${getUserSession().company}`,
      method: 'POST',
      contentType: 'auto',
      body: formData
    });
  }

  getPrivacyPolice(){
    return this.fetch({
      endpoint: `company/getPrivacyPolicy/${getUserSession().company}`,
      method: 'GET'
    });
  };
}
const companyAPI = new CompanyAPI();
export default companyAPI;