import { Box } from '@mui/material';
import theme from 'theme';
import praxioLogoWhite from 'assets/images/praxio_logo_white.png';

function DrawerBottom(){

  const style = {
    textAlign: 'center',
    width: theme.drawer.width,
    backgroundColor: '#353A40', //theme.palette.primary.main,
    borderTop: `2px solid #515858`,
    position: 'fixed',
    bottom: 0,
    py: 1,
  };

  return(
    <Box sx={style}>
      <img src={praxioLogoWhite}/>
    </Box>
  )
} export default DrawerBottom;