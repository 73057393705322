import organizerAPI from 'apis/OrganizerAPI';
import { getUserSession } from 'utils/userUtils';

export function getTasks(){
  const USER = getUserSession();
  const date = new Date();
  const today = new Date(date.setHours(23,59,59)).toISOString();

  return organizerAPI.getAll({ page: 1, limit: null }, undefined, 
    {
      condicaoWhere: {
        SITUACAO: 1,
        DATA_INICIO: { lte: today },
        DATA_FIM: {
          or: [
            { gte: date.setHours(0,0,0) },
            { eq: null }
          ]
        },
        DATA_CONCLUIDA: {
          or: [
            { eq: null },
            { lte: date.setHours(0, 0, 0) }
          ]
        },
        and: [
          {
            or: [
              {
                RECORRENCIA_TAREFA: 0,
                DATA_CONCLUIDA: null
              },
              {
                RECORRENCIA_TAREFA: 1,
                DATA_CONCLUIDA: null
              },
              {
                RECORRENCIA_TAREFA: 2,
                DATA_CONCLUIDA: null,
                DIA_SEMANA: date.getDay()
              },
              {
                RECORRENCIA_TAREFA: 3,
                DATA_CONCLUIDA: null,
                DIA_MES: date.getDate()
              },
              {
                RECORRENCIA_TAREFA: 4,
                DATA_CONCLUIDA: null
              }
            ]
          }
        ],
        or: [
          { TAREFA_GLOBAL: 1 },
          { GUID_PERFIL: USER.idProfile },
          { GUID_USU: USER.id }
        ]
      }
    }
  );
};

export function getParseRecurrence(recurrence: number){
  switch(recurrence){
    case 0:
      return 'Uma Vez';
    case 1:
      return 'Diário';
    case 2:
      return 'Semanal';
    case 4:
      return 'Dias Úteis';
    default:
      return 'Mensal';
  };
};

export function disableSoundAlert(ALERTA_SONORO: boolean, GUID: any){
  return organizerAPI.update({ ALERTA_SONORO: !ALERTA_SONORO }, GUID);
};